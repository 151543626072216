import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { connect, useDispatch } from 'react-redux';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  setError,
  setLocationIds
} from '../../../actions';
import NewSearchPageResults from '../../NewSearch/NewSearchPageResults';

const mapDispatchToProps = dispatch => {
  return {
    setErrorUI: errorState => dispatch(setError(errorState))
  };
};

const SearchPage = ({ transformedObj, setErrorUI, totalAdsCount, h1 }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const formEl = useRef(null);

  const resetForm = () => {
    const resetData = {
      city_id: '1',
      leased: 'false',
      category_id: '1'
    };
    formEl?.current?.reset(resetData);
    dispatch(setChosenLocationNames([]));
    dispatch(setLocationIds([]));
    dispatch(setCurrentCity('1'));
    dispatch(
      setCurrentState({
        ...resetData,
        category_touched: false
      })
    );

    router.push('/');
  };

  return (
    <NewSearchPageResults
      {...{ transformedObj, setErrorUI, h1, resetForm, totalAdsCount }}
      ref={formEl}
    />
  );
};

SearchPage.propTypes = {
  transformedObj: PropTypes.object,
  totalAdsCount: PropTypes.number,
  setErrorUI: PropTypes.func,
  h1: PropTypes.string
};

export default connect(null, mapDispatchToProps)(SearchPage);
